import { UUID } from "crypto";
import { ApiCaller } from "./ApiCaller";
import { SignupForm } from "../pages/SignUpPage/SignUpPage";
import { Member } from "./MemberApi";

export class AuthApi {
    private static apiCaller = new ApiCaller("members");

    public static Login(email: string, password: string, auth_code: string | undefined = undefined) {
        return this.apiCaller.POST("login", { email, password, auth_code });
    }

    public static Register(signUpForm: SignupForm) {
        return this.apiCaller.POST<any>("register", signUpForm);
    }

    public static ResetPasswordUnauthorized(email: string, newPassword: string = "", token: string = "") {
        let data: any = { email };
        if (newPassword.length > 0) {
            data.password = newPassword;
        }
        if (token.length > 0) {
            data.token = token;
        }
        return this.apiCaller.POST<any>("resetPasswordRequest", data);
    }

    public static ResetPasswordAuthenticated(current_password: string, password: string) {
        return this.apiCaller.POST("resetPassword", { current_password, password });
    }

    public static ResendAuthenticationCodeEmail(email: string) {
        return this.apiCaller.POST("resendAuthorizationCode", { email });
    }

    public static ResendVerificationEmail(email: string) {
        return this.apiCaller.POST("resendVerification", { email });
    }

    public static VerifyParentDisplayId(display_id: string, group_member: boolean = false) {
        return this.apiCaller.POST("validateParentDisplayId", { display_id, group_member });
    }

    public static VerifyEmail(verification_id: string, updating_email: boolean = false, password: string | null = null) {
        return this.apiCaller.POST("verify", { verification_id, updating_email, password });
    }

}
