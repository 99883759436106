import { Link, useParams } from "react-router-dom";
import { useShoppingCart } from "../../context/ShoppingCartContext";
import { useAuthenticatedUser } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import { BootstrapButton } from "../../components/BootstrapButton/BootstrapButton";
import "./product-details-page.scss";
import { MembershipInfoOffCanvas } from "../../components/MembershipInfoOffCanvas/MembershipInfoOffCanvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ProductDetailsSlider from "../../components/ProductDetailsSlider/ProductDetailsSlider";
import ShareComponent from "../../components/ShareComponent/ShareComponent";
import { Loader } from "../../components/Loader/Loader";

interface ProductDetailsPageProps {}

export const ProductDetailsPage = ({}: ProductDetailsPageProps) => {
    const searchParams = useParams();
    const { allProducts, getItemQuantity, increaseCartQuantity, decreaseCartQuantity, removeFromCart } = useShoppingCart();
    const { authMember, isLoggedIn } = useAuthenticatedUser();
    const [photoIndex, setPhotoIndex] = useState(0);
    const [labelImageIsValid, setLabelImageIsValid] = useState(true);
    const [ingredientImageIsValid, setIngredientImageIsValid] = useState(true);
    const [supportsImageIsValid, setSupportsImageIsValid] = useState(true);
    const [membershipInfoOffCanvasShow, setMembershipInfoOffCanvasShow] = useState(false);

    const product = allProducts.find((product) => product.id === searchParams.id);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (product) {
        const quantity = getItemQuantity(product.id);

        return (
            <>
                <section className="product-single container mb-5">
                    <div className="row">
                        <div className="d-flex justify-content-between pb-md-2 mt-3">
                            <div className="breadcrumb mb-0 flex-grow-1">
                                <div className="d-flex">
                                    <Link to="/products/" className="fs-6 text-primary fw-bold btn-catalog">
                                        PRODUCTS
                                    </Link>
                                    <div className="ms-2 fs-6"> / {product.name.toUpperCase()}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-2">
                            <ProductDetailsSlider content={product.content} />
                        </div>
                        <div className="col-lg-6">
                            <h1 className="product-single__name mb-5">{authMember?.active === false && product.id === "prod_QWwdJJD4NesYjh" ? `${product.name} / 60 day FREE Monthly Membership`: product.name}</h1>
                            <div className="mt-4">
                                <span className="fs-5 fw-bold">Retail:</span>
                                <span className="fs-5 ms-2 text-primary">{product.retail_price.unit_amount_formatted}</span>
                            </div>
                            <div className="">
                                <div className="d-flex fs-5 align-self-center">
                                    <div className="fw-bold">Member:</div>
                                    <div className="d-flex flex-column ms-2">
                                        <div className="fs-5">
                                            First:<span className="ms-1 text-primary">{product.member_single_price.unit_amount_formatted}</span>
                                            <FontAwesomeIcon
                                                onClick={() => setMembershipInfoOffCanvasShow(true)}
                                                className="mx-2 fs-5 product-item__info"
                                                icon={faInfoCircle}
                                            />
                                        </div>
                                        <div className="fs-5 mb-2">
                                            Each Additional:{" "}
                                            <span className="ms-1  text-primary">{product.member_multiple_price.unit_amount_formatted}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="product-single__short-desc">
                                <div dangerouslySetInnerHTML={{ __html: product.description as any }} />
                            </div>
                            <form onSubmit={(e) => e.preventDefault()}>
                                {quantity === 0 ? (
                                    <>
                                        {product.is_coming_soon && (
                                            <div className="product-item__out_of p-2 px-3 d-flex justify-content-center">Coming Soon</div>
                                        )}
                                        {!product.is_coming_soon && product.is_back_ordered && (
                                            <div className="product-item__out_of p-2 px-3 d-flex justify-content-center">Out of Stock</div>
                                        )}
                                        {!product.is_back_ordered && !product.is_coming_soon && (
                                            <div className="product-single__addtocart">
                                                <div className="qty-control position-relative">
                                                    <BootstrapButton
                                                        className={`btn-addtocart js-open-aside`}
                                                        onClick={() => (quantity > 0 ? removeFromCart(product.id) : increaseCartQuantity(product))}
                                                        title={quantity > 0 ? "Remove" : "Add to Cart"}
                                                        variant={quantity > 0 ? "outline-danger" : "secondary"}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="product-single__addtocart">
                                        <div className="qty-control position-relative">
                                            <input
                                                type="number"
                                                name="quantity"
                                                value={quantity}
                                                min="1"
                                                disabled
                                                className="qty-control__number text-center"
                                            />
                                            <div onClick={() => decreaseCartQuantity(product.id)} className="qty-control__reduce">
                                                -
                                            </div>
                                            <div onClick={() => increaseCartQuantity(product)} className="qty-control__increase">
                                                +
                                            </div>
                                        </div>
                                        <BootstrapButton
                                            className={`btn-addtocart js-open-aside`}
                                            onClick={() => (quantity > 0 ? removeFromCart(product.id) : increaseCartQuantity(product))}
                                            title={quantity > 0 ? "Remove" : "Add to Cart"}
                                            variant={quantity > 0 ? "outline-danger" : "secondary"}
                                        />
                                    </div>
                                )}
                            </form>
                            <div className="product-single__addtolinks mt-3">
                                <ShareComponent title={product.name} />
                            </div>
                        </div>
                    </div>
                    <div className="product-single__details-tab mt-5">
                        <ul className="nav nav-tabs" id="myTab1" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link nav-link_underscore active"
                                    id="tab-description-tab"
                                    data-bs-toggle="tab"
                                    href="#tab-description"
                                    role="tab"
                                    aria-controls="tab-description"
                                    aria-selected="true"
                                >
                                    Description
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link nav-link_underscore"
                                    id="tab-additional-info-tab"
                                    data-bs-toggle="tab"
                                    href="#tab-additional-info"
                                    role="tab"
                                    aria-controls="tab-additional-info"
                                    aria-selected="false"
                                >
                                    Additional Information
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link nav-link_underscore"
                                    id="tab-video-tab"
                                    data-bs-toggle="tab"
                                    href="#tab-video"
                                    role="tab"
                                    aria-controls="tab-video"
                                    aria-selected="false"
                                >
                                    Video
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="tab-description" role="tabpanel" aria-labelledby="tab-description-tab">
                                {/* <Description /> */}
                            </div>
                            <div className="tab-pane fade" id="tab-additional-info" role="tabpanel" aria-labelledby="tab-additional-info-tab">
                                {/* <AdditionalInfo /> */}
                            </div>
                            <div className="tab-pane fade" id="tab-video" role="tabpanel" aria-labelledby="tab-video-tab">
                                {/* <video /> */}
                            </div>
                        </div>
                    </div>
                </section>
                <MembershipInfoOffCanvas
                    membershipInfoOffCanvasShow={membershipInfoOffCanvasShow}
                    setMembershipInfoOffCanvasShow={setMembershipInfoOffCanvasShow}
                />
            </>
        );
    } else {
        return <Loader />
    }
};
