import React, { useEffect, useState } from "react";
import "./verify-page.scss";
import Logo from "../../assets/images/logo/main.svg";
import { BootstrapButton } from "../../components/BootstrapButton/BootstrapButton";
import { AuthApi } from "../../apis/AuthApi";
import { Loader } from "../../components/Loader/Loader";
import { SignInOffCanvas } from "../../components/SignInOffCanvas/SignInOffCanvas";
import { PasswordResetOffCanvas } from "../../components/PasswordResetOffCanvas/PasswordResetOffCanvas";
import { useLocation, useNavigate } from "react-router-dom";
import { BootstrapInput } from "../../components/BootstrapInput/BootstrapInput";
import { Container } from "react-bootstrap";
import { passMeetsRequirements } from "../../utils/string";

interface VerifyPageProps {}

export const VerifyPage = ({}: VerifyPageProps) => {
    const [verifyingAccount, setVerifyingAccount] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [password, setPassword] = useState("");
    const [passwordValid, setPasswordValid] = useState(true);
    const [accountIsVerified, setAccountIsVerified] = useState(false);
    const [signInOffCanvasShow, setSignInOffCanvasShow] = useState(false);
    const [passwordResetOffCanvasShow, setPasswordResetOffCanavasShow] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    useEffect(() => {
        let verification_id = location.search.replace("?", "");
        if (verification_id && verification_id.length > 0) {
            AuthApi.VerifyEmail(verification_id)
                .then((response) => {
                    if (response.success) {
                        setAccountIsVerified(true);
                    } else {
                        setAccountIsVerified(false);
                    }
                })
                .catch(console.error)
                .finally(() => setVerifyingAccount(false));
        } else {
            setVerifyingAccount(false);
            setError("Something unexpected happened...");
        }
    }, []);

    const handleSetPasswordAndOpenSignInOffCanvas = () => {
        const passwordToUse = password.length > 0 && password === confirmPassword && passMeetsRequirements(password);
        const passwordErrorMessage = "Password fields don't meet the requirements or they aren't matching.";
        setPasswordValid(passwordToUse);
        debugger; 
        if(passwordToUse) {

            let verification_id = location.search.replace("?", "");
            setVerifyingAccount(true);
            AuthApi.VerifyEmail(verification_id, false, password)
            .then((response) => {
                if (response.success) {
                    setVerifyingAccount(true);
                    setSignInOffCanvasShow(true);
                } else {
                    setVerifyingAccount(false);
                    setPasswordError(passwordErrorMessage);
                }
            })
            .catch(console.error)
        } else {
            if (!passwordToUse) {
                setPasswordError(passwordErrorMessage);
            }
        }
    }

    return (
        <>
            <SignInOffCanvas
                signInOffCanvasShow={signInOffCanvasShow}
                setSignInOffCanvasShow={setSignInOffCanvasShow}
                setPasswordResetOffCanvasShow={setPasswordResetOffCanavasShow}
            />
            <PasswordResetOffCanvas
                passwordResetOffCanvasShow={passwordResetOffCanvasShow}
                setPasswordResetOffCanvasShow={setPasswordResetOffCanavasShow}
                setSignInOffCanvasShow={setSignInOffCanvasShow}
            />
            {verifyingAccount ? (
                <Loader />
            ) : (
                <div className="page-content">
                    <div className="d-flex  d-block d-md-none ">
                        <div className="d-flex mt-5 ">
                            <img src={"https://topshelfnutra.com/main.svg"} alt="" className="img-fluid mx-auto" style={{ width: "150px" }} />
                        </div>
                    </div>

                    <div className="d-flex d-none d-md-block mx-auto">
                        <div className="d-flex mt-5 ">
                            <img src={"https://topshelfnutra.com/main.svg"} alt="" className="img-fluid mx-auto" />
                        </div>
                    </div>

                    <div className="mt-5 p-4">
                        {error.length > 0 ? (
                            <div className="d-flex justify-content-center">{error}</div>
                        ) : (
                            <div className="d-flex flex-column">
                                <div className="mx-auto">
                                    <div className="fs-3 fw-bold">{accountIsVerified ? "Account Verified" : "Verification Failed"}</div>
                                </div>
                                <div className="mx-auto mt-4">
                                    <div className="fs-4 text-center fw-bold">
                                        {accountIsVerified
                                            ? "Please create a password that follows the password requirements below:"
                                            : "An error has occured while trying to verify you account."}
                                    </div>
                                </div>
                                {accountIsVerified ? (
                                    <Container className="mt-3">
                                        <div className="d-inline d-md-flex flex-md-column align-items-center">
                                            <div>
                                                {passwordError.length > 0 && <div className="my-2 tsn-red text-center">{passwordError}</div>}
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="password-field position-relative">
                                                    <BootstrapInput
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        label="Password"
                                                        valid={passwordValid}
                                                        type="password"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <BootstrapInput
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                    label="Confirm Password"
                                                    valid={passwordValid}
                                                    type="password"
                                                />
                                            </div>
                                            <div className="col-12 col-md-4 mt-2 me-4 mb-4 text-center">
                                                <div className="fs-6 fw-lighter ">
                                                    {" "}
                                                    <span className="fw-bold">Password requirements:</span> minimum 8 characters, at least one
                                                    uppercase letter, one number, and one special character.
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 mx-auto mt-4 d-flex justify-content-center">
                                                <div className="fs-3 fw-bold">
                                                    <BootstrapButton
                                                        onClick={handleSetPasswordAndOpenSignInOffCanvas}
                                                        className="p-2 px-3"
                                                        variant="secondary"
                                                        title="Sign-In to My Account"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Container>
                                ) : (
                                    <div className="mx-auto mt-4">
                                        <BootstrapButton
                                            onClick={() => navigate("/")}
                                            className="p-2 px-3"
                                            variant="secondary"
                                            title="Go to Dashboard"
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
