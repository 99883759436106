import React, { useState } from 'react';

type AccordionProps = {
    item: AccordionData;
}

type AccordionData = {
    id: number,
    heading: string;
    body: string;
}

export const Accordion = ({ item }: AccordionProps) => {
    const [expanded, setExpanded] = useState(false);

    return(
        <>
        <div key={item.id} className="faq-accordion accordion mb-2">
            <div className="accordion-item">
              <h5
                className="accordion-header"
              >
                <button
                  className={`px-3 accordion-button ${
                    !expanded ? "collapsed" : ""
                  }`}
                  type="button"
                  onClick={() => setExpanded(!expanded)}
                //   data-bs-toggle="collapse"
                //   data-bs-target={`#faq-accordion-collapse-2-${item.id}`}
                //   aria-expanded={expanded}
                //   aria-controls={`faq-accordion-collapse-2-${item.id}`}
                >
                  {item.heading}
                  <svg className="accordion-button__icon" viewBox="0 0 14 14">
                    <g aria-hidden="true" stroke="none" fillRule="evenodd">
                      <path
                        className="svg-path-vertical"
                        d="M14,6 L14,8 L0,8 L0,6 L14,6"
                      ></path>
                      <path
                        className="svg-path-horizontal"
                        d="M14,6 L14,8 L0,8 L0,6 L14,6"
                      ></path>
                    </g>
                  </svg>
                </button>
              </h5>
              <div
                className={`accordion-collapse collapse ${
                  expanded ? "show" : ""
                }`}
                // aria-labelledby={`faq-accordion-heading-2-${item.id}`}
                // data-bs-parent="#faq_accordion_2"
              >
                <div className="accordion-body">
                  <p>{item.body}</p>
                </div>
              </div>
            </div>
        </div>
        </>
    )
}