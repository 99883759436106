import { Col, Container, Row } from "react-bootstrap";
import { useAuthenticatedUser } from "../../../context/AuthContext";
import { BootstrapButton } from "../../../components/BootstrapButton/BootstrapButton";
import { StripeApi, Subscription } from "../../../apis/StripeApi";
import { SubscriptionOffCanvas } from "../../../components/SubscriptionOffCanvas/SubscriptionOffCanvas";
import { useEffect, useState } from "react";
import { CustomerApi } from "../../../apis/CustomerApi";
import { Member, MemberApi } from "../../../apis/MemberApi";
import { Bootstrap, Calendar2Check } from "react-bootstrap-icons";
import { PaymentMethod as StripePaymentMethod } from "@stripe/stripe-js";
import { PaymentMethod } from "../../../components/PaymentMethod/PaymentMethod";
import { AddCardOffCanvas } from "../../../components/AddCardOffCanvas/AddCardOffCanvas";
import { BootstrapInput } from "../../../components/BootstrapInput/BootstrapInput";
import "./membership.scss";
import Stripe from "stripe";
import {
    production_group_owner_forty,
    production_group_owner_ten,
    production_group_owner_twenty,
    test_group_owner_forty,
    test_group_owner_ten,
    test_group_owner_twenty,
} from "../../../App";
import { GroupMemberListItem } from "../../../components/GroupMemberListItem/GroupMemberListItem";
import { BootstrapDropdown } from "../../../components/BootstrapDropdown/BootstrapDropdown";

interface MembershipProps {
    membership: Stripe.Product | undefined;
    isPrelaunch: boolean;
    subscriptions: Stripe.Product[];
    paymentMethods: Stripe.PaymentMethod[];
    setPaymentMethods: React.Dispatch<React.SetStateAction<Stripe.PaymentMethod[]>>;
    getMembershipDetails: () => Promise<void>;
    setLoadingAccountPage: React.Dispatch<React.SetStateAction<boolean>>;
    handleSetDefaultPaymentMethod: (customer_id: string, card_id: string) => Promise<void>;
    groupMembers: Member[];
    setGroupMembers: React.Dispatch<React.SetStateAction<Member[]>>;
    groupMembership: boolean;
    subscriptionId: string;
}

export const Membership = ({
    subscriptions,
    membership,
    isPrelaunch,
    paymentMethods,
    setPaymentMethods,
    getMembershipDetails,
    setLoadingAccountPage,
    handleSetDefaultPaymentMethod,
    groupMembers,
    setGroupMembers,
    groupMembership,
    subscriptionId,
}: MembershipProps) => {
    const { authMember, updateAuthMember } = useAuthenticatedUser();
    const [subscriptionOffCanvasShow, setSubscriptionOffCanvasShow] = useState(false);
    const [addCardOffCanvasShow, setAddCardOffCanvasShow] = useState(false);
    const [subscriptionClientSecret, setSubscriptionClientSecret] = useState("");
    const [cardClientSecret, setCardClientSecret] = useState("");
    const [sessionId, setSessionId] = useState("");
    const [confirmCancel, setConfirmCancel] = useState("");
    const [confirmCancelClick, setConfirmCancelClick] = useState(false);
    const [choosenSubscriptionName, setChoosenSubscriptionName] = useState("");
    const [searchMembersTerm, setSearchMembersTerm] = useState("");
    const [removeMonthlySubscriptionOnComplete, setRemoveMonthlySubscriptionOnComplete] = useState(false);
    const [verifiedFilter, setVerifiedFilter] = useState<"All" | "Verified" | "Unverified">("All");
    const groupMembershipIds = [
        test_group_owner_ten,
        test_group_owner_twenty,
        test_group_owner_forty,
        production_group_owner_ten,
        production_group_owner_twenty,
        production_group_owner_forty,
    ];
    const allowedEmails: string[] = [];

    const splitSubscriptions = (subscriptions: Stripe.Product[], type: "Single" | "Multiple") => {
        if (type === "Single") {
            return subscriptions.filter((sub) => !groupMembershipIds.find((id) => sub.id === id));
        } else {
            return subscriptions.filter((sub) => groupMembershipIds.find((id) => sub.id === id));
        }
    };

    const buyMembershipClick = async (subscription: Stripe.Product, removeMembership: boolean = false) => {
        if (authMember) {
            if (subscription.default_price) {
                CustomerApi.CreateSubscriptionSession(authMember.customer_id, subscription.default_price as string)
                    .then((response) => {
                        setSubscriptionClientSecret(response.client_secret);
                        setSessionId(response.id);
                        setChoosenSubscriptionName(subscription.name);
                        if (removeMembership) {
                            setConfirmCancel("confirm");
                            setRemoveMonthlySubscriptionOnComplete(true);
                        }
                    })
                    .catch(console.error)
                    .finally(() => {
                        setSubscriptionOffCanvasShow(true);
                    });
            }
        }
    };

    useEffect(() => {
        if (!subscriptionOffCanvasShow && sessionId.length > 0) {
            CustomerApi.ExpireCheckoutSession(sessionId);
            setSubscriptionClientSecret("");
            setSessionId("");
        }
        if (!subscriptionOffCanvasShow) {
            setCardClientSecret("");
            setRemoveMonthlySubscriptionOnComplete(false);
            setConfirmCancel("");
        }
    }, [subscriptionOffCanvasShow]);

    const handleIsMemberActive = async () => {
        if (authMember) {
            let activeCheck = await CustomerApi.CheckActiveMembership(authMember.customer_id);
            if (activeCheck.success && authMember) {
                updateAuthMember({ ...authMember, active: activeCheck.valid_member });
            } else {
                console.log("Something went wrong while checking for member status.");
            }
        }
    };

    const handleRemovePaymentMethod = async (customer_id: string, card_id: string) => {
        let response = await CustomerApi.RemovePaymentMethod(customer_id, card_id).catch(console.error);
        if (response && authMember) {
            let copyOfPaymentMethods = [...paymentMethods];
            copyOfPaymentMethods = copyOfPaymentMethods.filter((paymentMethod) => paymentMethod.id !== card_id);
            setPaymentMethods(copyOfPaymentMethods);
        }
    };

    const handleUpdatePaymentMethod = async (card_id: string, exp_month: string, exp_year: string, postal_code: string) => {
        let response = await CustomerApi.UpdatePaymentMethod(card_id, exp_month, exp_year, postal_code).catch(console.error);
        if (response && response.success && authMember) {
            getPaymentMethods();
        }
    };

    const getPaymentMethods = async () => {
        if (authMember) {
            let response = await CustomerApi.GetPaymentMethods(authMember.customer_id);
            if (response) {
                setPaymentMethods(response);
            }
        }
    };

    const addACardClick = async () => {
        if (authMember) {
            let response = await CustomerApi.SetupIntent(authMember?.customer_id).catch(console.error);
            if (response) {
                setCardClientSecret(response.client_secret);
                setAddCardOffCanvasShow(true);
            }
        }
    };

    const onCancelSubscriptionOnClick = async (membership: any) => {
        if (subscriptionId.length > 0 && confirmCancel === "confirm" && authMember) {
            let response = await StripeApi.CancelSubscription(subscriptionId, authMember.member_id);
            console.log(response);
            if (response.success) {
                setLoadingAccountPage(true);
                getMembershipDetails();
            } else {
                console.log(response.message);
            }
        }
    };

    const handleSetVerifiedFilter = (verifiedValue: number) => {
        switch (verifiedValue) {
            case 1:
                setVerifiedFilter("All");
                break;
            case 2:
                setVerifiedFilter("Verified");
                break;
            case 3:
                setVerifiedFilter("Unverified");
                break;
        }
    };

    return (
        <>
            <AddCardOffCanvas
                addCardOffCanvasShow={addCardOffCanvasShow}
                setAddCardOffCanvasShow={setAddCardOffCanvasShow}
                clientSecret={cardClientSecret}
                getPaymentMethods={getPaymentMethods}
            />
            <SubscriptionOffCanvas
                subscriptionOffCanvasShow={subscriptionOffCanvasShow}
                setSubscriptionOffCanvasShow={setSubscriptionOffCanvasShow}
                clientSecret={subscriptionClientSecret}
                handleIsMemberActive={handleIsMemberActive}
                getMembershipDetails={getMembershipDetails}
                setLoadingAccountPage={setLoadingAccountPage}
                subscriptionName={choosenSubscriptionName}
                upgradeMonthlySubscription={{
                    upgrade: removeMonthlySubscriptionOnComplete,
                    cancelMembership: onCancelSubscriptionOnClick,
                    membershipToCancel: membership,
                }}
            />
            <Container fluid className="mt-3 mt-sm-3 mt-md-3 mt-lg-0 mb-3 wrapper-tab wrapper-affiliate d-block stage-container">
                <div className="fs-4 mb-4 fw-bold">Membership Information</div>
                {!authMember?.active || groupMembership ? (
                    <Row>
                        {groupMembership && (
                            <>
                                <Col md={6} className="pb-3 mb-4 col-12 fs-4 text-body-emphasis font-italic">
                                    Part of a Group Membership Subscription
                                </Col>
                                <Col
                                    md={6}
                                    className="pb-3 mb-4 col-12 d-flex justify-content-start justify-content-md-end align-items-center fs-4 fw-bold ms-auto text-success"
                                >
                                    <Calendar2Check className="me-2 mt-1 fs-4" />
                                    Active
                                </Col>
                            </>
                        )}
                        <Col className="member__group_sub_bottom pb-3 my-4 col-12 fs-4">Single Subscriptions</Col>
                        {splitSubscriptions(subscriptions, "Single").map((subscription) => (
                            <Col
                                key={subscription.id}
                                sm={12}
                                md={12}
                                lg={5}
                                className="member__subscription_container p-4 m-0 m-lg-3 my-3 col-12 d-flex flex-column"
                            >
                                <Row>
                                    <Col className="col-12 d-flex d-md-block justify-content-center">
                                        <h4 className="fw-semibold mb-2 fs-5">{subscription.name}</h4>
                                    </Col>
                                    <Col className="col-12 d-flex d-md-block justify-content-center">
                                        <p className="text-body-secondary mb-2">{subscription.description}</p>
                                    </Col>
                                    <Col className="col-12 d-flex d-md-block justify-content-center">
                                        <BootstrapButton
                                            onClick={() => buyMembershipClick(subscription)}
                                            variant="secondary"
                                            title={`Buy ${subscription.name}`}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        ))}
                        {authMember && allowedEmails.find((email) => email.toLowerCase().trim() === authMember.email.toLowerCase().trim()) && (
                            <>
                                <Col className="member__group_sub_bottom pb-3 my-4 col-12 fs-4">Group Subscriptions</Col>
                                {splitSubscriptions(subscriptions, "Multiple").map((subscription) => (
                                    <Col
                                        key={subscription.id}
                                        sm={12}
                                        md={12}
                                        lg={5}
                                        className="member__subscription_container p-4 m-0 m-lg-3 my-3 col-12 d-flex flex-column"
                                    >
                                        <Row>
                                            <Col className="col-12 d-flex d-md-block justify-content-center">
                                                <h4 className="fw-semibold mb-2 fs-5">{subscription.name}</h4>
                                            </Col>
                                            <Col className="col-12 d-flex d-md-block justify-content-center">
                                                <p className="text-body-secondary mb-2">{subscription.description}</p>
                                            </Col>
                                            <Col className="col-12 d-flex d-md-block justify-content-center">
                                                <BootstrapButton
                                                    onClick={() => buyMembershipClick(subscription)}
                                                    variant="secondary"
                                                    title={`Buy ${subscription.name}`}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                ))}
                            </>
                        )}
                    </Row>
                ) : (
                    <div className="col-12 mb-5 wrapper-tab wrapper-billing" data-navigation="billing">
                        {(isPrelaunch || membership) && (
                            <div className="wrapper-subscription-info">
                                <Row className="d-flex">
                                    <Col md={6} className="col-12 fs-4 text-body-emphasis">
                                        {isPrelaunch && "Pre-Launch One Year Membership"}
                                        {membership && membership.name}
                                    </Col>
                                    <Col
                                        md={6}
                                        className="col-12 d-flex justify-content-start justify-content-md-end align-items-center fs-4 fw-bold ms-auto text-success"
                                    >
                                        <Calendar2Check className="me-2 mt-1 fs-4" />
                                        Active
                                    </Col>
                                    {groupMembershipIds.find((group_membership) => membership && group_membership === membership.id) && (
                                        <Row className="mt-3">
                                            <Col xl={12} className="fs-5 fw-bold my-2">
                                                Group Members List{" "}
                                                {groupMembers.length > 0 ? `(${groupMembers.length} - ${authMember.group_member_limit} Max)` : ""}
                                            </Col>
                                            <Col sm={12} lg={5} className="my-2">
                                                <BootstrapInput
                                                    label="Search Group Members"
                                                    value={searchMembersTerm}
                                                    onChange={(e) => setSearchMembersTerm(e.target.value)}
                                                />
                                            </Col>
                                            <Col sm={12} lg={5} className="my-2">
                                                <BootstrapDropdown
                                                    value={verifiedFilter}
                                                    onClick={handleSetVerifiedFilter}
                                                    label="Verified/Unverified"
                                                    dropdownItems={[
                                                        { title: "All", id: 1 },
                                                        { title: "Verified", id: 2 },
                                                        { title: "Unverified", id: 3 },
                                                    ]}
                                                />
                                            </Col>
                                            <Col sm={12} md={9} lg={8}>
                                                <p>
                                                    Note - Anyone you add to your Group Membership will grant them access to Membership pricing for{" "}
                                                    <b>FREE</b>. They simply sign-up using your Group Link and that's it! Membership pricing granted!
                                                </p>
                                            </Col>
                                            <Col sm={12} md={12} lg={8} className="mx-2 mt-2 list-group group-membership-container">
                                                {groupMembers.length > 0 ? (
                                                    groupMembers
                                                        .filter((member) => {
                                                            if (verifiedFilter === "All") {
                                                                return member;
                                                            } else if (verifiedFilter === "Verified" && member.verified_on) {
                                                                return member;
                                                            } else if (verifiedFilter === "Unverified" && !member.verified_on) {
                                                                return member;
                                                            }
                                                        })
                                                        .filter((member) => {
                                                            if (searchMembersTerm.length === 0) {
                                                                return member;
                                                            } else {
                                                                if (
                                                                    member.first_name.toLowerCase().includes(searchMembersTerm.toLowerCase().trim())
                                                                ) {
                                                                    return member;
                                                                }
                                                                if (member.last_name.toLowerCase().includes(searchMembersTerm.toLowerCase().trim())) {
                                                                    return member;
                                                                }
                                                                if (member.email.toLowerCase().includes(searchMembersTerm.toLowerCase().trim())) {
                                                                    return member;
                                                                }
                                                            }
                                                        })
                                                        .map((member) => {
                                                            return (
                                                                <GroupMemberListItem
                                                                    groupMembers={groupMembers}
                                                                    setGroupMembers={setGroupMembers}
                                                                    key={member.member_id}
                                                                    member={member}
                                                                />
                                                            );
                                                        })
                                                ) : (
                                                    <li className="list-group-item">
                                                        No members have been added yet. Click{" "}
                                                        <div className="fw-bold d-inline">Affiliate Program</div> on the left to copy your group link.
                                                        You can give that out to your friends and family to get membership pricing on all products.
                                                    </li>
                                                )}
                                            </Col>
                                        </Row>
                                    )}
                                    {(subscriptions.find((sub) => sub.metadata?.is_yearly === "true") &&
                                        !isPrelaunch &&
                                        membership?.id === "prod_QKZr3CM7hkOTG6") ||
                                        (membership?.id === "prod_QOd1SiJr5BPTuP" && (
                                            <>
                                                <Col className="mt-4 fs-5 member__upgrade_subscription_title">
                                                    <div
                                                        className="cursor-pointer"
                                                        onClick={() =>
                                                            buyMembershipClick(
                                                                subscriptions.find((sub) => sub.metadata?.is_yearly === "true") as Stripe.Product,
                                                                true
                                                            )
                                                        }
                                                    >
                                                        Upgrade to Yearly Subscription
                                                    </div>
                                                </Col>
                                                <Col className="col-12 fs-6 member__upgrade_subscription">
                                                    This will cancel your monthly subscription and upgrade your account to our yearly recurring
                                                    subscription. Our yearly renewed subscription comes with 30 PDC's upon purchase, <b>ONLY $180</b>{" "}
                                                    a year.
                                                </Col>
                                            </>
                                        ))}
                                </Row>
                            </div>
                        )}
                    </div>
                )}
                {window.location.search.includes("updateCC=true") && (
                    <div className="w-100 my-3 tsn-red border p-3">
                        {window.location.search.includes("subscription=true")
                            ? "You have failed to make your subscription payment due to the card found in your email. Please update it to continue having an active TopShelfNutra Membership. If you no longer want to use this card, add a new one and set it to default. Note - We will run your card again in the next few days to try again."
                            : "You have a card that is expiring soon. Please remove or update the card found in your email."}
                    </div>
                )}
                <div className="w-100 my-4 wrapper-card-information d-block">
                    <Row className="d-flex w-100 border-bottom">
                        <Col md={9} className="col-12 fs-5 fw-bold mb-2">
                            Saved Payment Methods
                        </Col>
                        <Col md={3} className="my-3 my-md-0 col-12 ms-auto fs-6 d-flex justify-content-start justify-content-md-end">
                            <a onClick={addACardClick} className="cursor-pointer text-reset">
                                Add a Card
                            </a>
                        </Col>
                    </Row>
                    {authMember && (
                        <Row className="w-100">
                            {paymentMethods.length > 0 ? (
                                paymentMethods.map((method, i) => (
                                    <PaymentMethod
                                        key={`${method.created}_${i}`}
                                        authMember={authMember}
                                        method={method}
                                        handleSetDefaultPaymentMethod={handleSetDefaultPaymentMethod}
                                        handleRemovePaymentMethod={handleRemovePaymentMethod}
                                        handleUpdatePaymentMethod={handleUpdatePaymentMethod}
                                    />
                                ))
                            ) : (
                                <div className="wrapper-customer-cards w-100">
                                    <div className="fs-5">(No credit cards on file)</div>
                                </div>
                            )}
                        </Row>
                    )}
                </div>
                {membership && !isPrelaunch && (
                    <>
                        <div className="w-100 my-2 wrapper-card-information d-block">
                            <Row className="d-flex w-100 border-bottom">
                                <Col md={9} className="col-12 fs-5 fw-bold mb-2">
                                    Cancel Subscription
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col className="col-12" md={6} lg={6}>
                                {!confirmCancelClick ? (
                                    <BootstrapButton
                                        onClick={() => setConfirmCancelClick(true)}
                                        variant="outline-danger"
                                        title="Cancel Current Membership"
                                    />
                                ) : (
                                    <div>
                                        <div>
                                            Are you sure you want to cancel your membership? If so, type 'confirm' in the field below and click the
                                            confirm button.
                                        </div>
                                        <div className="mt-3 d-flex align-items-center">
                                            <BootstrapInput
                                                value={confirmCancel}
                                                onChange={(e) => setConfirmCancel(e.target.value)}
                                                placeholder="Type confirm"
                                            />
                                            <BootstrapButton
                                                onClick={() => {
                                                    setConfirmCancelClick(false);
                                                    setConfirmCancel("");
                                                }}
                                                className="ms-2"
                                                variant="secondary"
                                                title="Cancel"
                                            />
                                            <BootstrapButton
                                                onClick={() => confirmCancel.toLowerCase() === "confirm" && onCancelSubscriptionOnClick(membership)}
                                                className="ms-2"
                                                variant="outline-primary"
                                                title="Confirm"
                                            />
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </>
                )}
            </Container>
        </>
    );
};
