import react, { useState } from "react";
import { Product } from "../../../apis/ProductApi";
import { BootstrapButton } from "../../../components/BootstrapButton/BootstrapButton";
import { useShoppingCart } from "../../../context/ShoppingCartContext";
import { useAuthenticatedUser } from "../../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navigation, Thumbs } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/thumbs";
import "swiper/css";
import "photoswipe/dist/photoswipe.css";
import "./product-item.scss";
import { Link } from "react-router-dom";
import { faChevronLeft, faChevronRight, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

interface ProductItemProps {
    product: Product;
    setMembershipInfoOffCanvasShow: React.Dispatch<React.SetStateAction<boolean>>;
    i: number;
}

export const ProductItem = ({ product, setMembershipInfoOffCanvasShow, i }: ProductItemProps) => {
    const { getItemQuantity, increaseCartQuantity, decreaseCartQuantity, removeFromCart } = useShoppingCart();
    const { authMember } = useAuthenticatedUser();
    const quantity = getItemQuantity(product.id);

    return (
        <div className="product-card-wrapper">
            <div className="product-card mb-3 mb-md-4 mb-xxl-5">
                <div className="pc__img-wrapper border">
                    <Swiper
                        className="shop-list-swiper   swiper-container swiper-initialized swiper-horizontal swiper-backface-hidden background-img js-swiper-slider"
                        slidesPerView={1}
                        modules={[Navigation]}
                        navigation={{
                            prevEl: ".prev" + i,
                            nextEl: ".next" + i,
                        }}
                    >
                        {product.content && product.content.length > 0 ? (
                            product.content.map((elm2, i) => {
                                return (
                                    <SwiperSlide key={i} className="swiper-slide">
                                        <Link to={`/products/${product.id}`}>
                                            <img
                                                loading="lazy"
                                                src={`https://res.cloudinary.com/dbltuv7gx/image/upload/${elm2}`}
                                                alt="Cropped Faux leather Jacket"
                                                className="pc__img"
                                            />
                                        </Link>
                                    </SwiperSlide>
                                );
                            })
                        ) : (
                            <SwiperSlide key={i} className="swiper-slide">
                                <Link to={`/products/${product.id}`}>
                                    <img loading="lazy" src={`https://topshelfnutra.com/main.svg`} style={{ objectFit: "contain" }} />
                                </Link>
                            </SwiperSlide>
                        )}

                        <span className={`cursor-pointer pc__img-prev ${"prev" + i} `}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </span>
                        <span className={`cursor-pointer pc__img-next ${"next" + i} `}>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </span>
                    </Swiper>
                    <div className="w-100 pc__atc btn anim_appear-bottom btn position-absolute border-0 text-uppercase fw-medium js-add-cart js-open-aside d-flex align-items-center justify-content-between mt-3">
                        <div className="product-single__addtocart w-100">
                            {quantity > 0 && (
                                <div className="qty-control position-relative">
                                    <input
                                        type="number"
                                        name="quantity"
                                        value={quantity}
                                        min="1"
                                        disabled
                                        className="qty-control__number text-center"
                                    />
                                    <div onClick={() => decreaseCartQuantity(product.id)} className="qty-control__reduce">
                                        -
                                    </div>
                                    <div onClick={() => increaseCartQuantity(product)} className="qty-control__increase">
                                        +
                                    </div>
                                </div>
                            )}
                            <div className="d-flex justify-content-center w-100">
                                {!product.is_back_ordered && !product.is_coming_soon && (
                                    <BootstrapButton
                                        className={`btn-addtocart js-open-aside`}
                                        onClick={() => (quantity > 0 ? removeFromCart(product.id) : increaseCartQuantity(product))}
                                        title={quantity > 0 ? "Remove" : "Add to Cart"}
                                        variant={quantity > 0 ? "danger" : "secondary"}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pc__info position-relative">
                    {product.is_coming_soon && <div className="product-item__out_of product-item__absolute-to-card p-2 px-3">Coming Soon</div>}
                    {!product.is_coming_soon && product.is_back_ordered && (
                        <div className="product-item__out_of product-item__absolute-to-card p-2 px-3">Out of Stock</div>
                    )}
                    <h6 style={{ height: "35px" }}>
                        <Link className="card-title fs-5 fw-bold text-center mb-1" to={`/products/${product.id}`}>
                        {authMember?.active === false && product.id === "prod_QWwdJJD4NesYjh" ? `${product.name} / 60 day FREE Monthly Membership`: product.name}
                        </Link>
                    </h6>
                    <div className="product-card__price d-flex">
                        {product.member_single_price ? (
                            <div className="d-flex flex-column justify-content-between align-items-start mt-3">
                                {!authMember?.active && (
                                    <div>
                                        <div className="fs-6 fw-bold mb-2">
                                            Retail:
                                            <span className="product-pricing ms-1">{product.retail_price.unit_amount_formatted}</span>
                                        </div>
                                    </div>
                                )}
                                <div className="flex-column">
                                    <div className="d-flex">
                                        <div className="fs-6 fw-bold mb-2">Member:</div>
                                        <div className="d-flex flex-column ms-2">
                                            <div className="fs-6 fw-bold mb-2">
                                                {authMember && authMember.active && "First:"}
                                                <span className={`${authMember && authMember.active ? "product-pricing ms-1" : ""}`}>
                                                    {product.member_single_price.unit_amount_formatted}{" "}
                                                    <FontAwesomeIcon
                                                        onClick={() => setMembershipInfoOffCanvasShow(true)}
                                                        className="fs-5 product-item__info"
                                                        icon={faInfoCircle}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {authMember && authMember.active && (
                                        <div className="fs-6 fw-bold mb-2">
                                            Each Additional:
                                            <span className="product-pricing ms-1">{product.member_multiple_price.unit_amount_formatted}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="d-flex flex-column justify-content-between align-items-center mt-3">
                                <div>Pricing Coming Soon</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
