import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { SwiperSlide, Swiper } from "swiper/react";

export const testimonials = [
    {
        id: 1,
        quote: "Ipsum dolor sit amet, consectetur adipiscing elit. Fringilla vel tincidunt ipsum ac. Nam at et id leo pulvinar egestas mi lorem. Adipiscing felis, vel faucibus in. Hendrerit viverra elementum venenatis pellentesque pellentesque ornare",
        author: "Helena Gibbson",
        date: "06 April 2023",
        imgSrc: "/assets/images/home/demo4/testimonial_avatar.png",
        imgWidth: 100,
        imgHeight: 100,
    },
    {
        id: 2,
        quote: "Ipsum dolor sit amet, consectetur adipiscing elit. Fringilla vel tincidunt ipsum ac. Nam at et id leo pulvinar egestas mi lorem. Adipiscing felis, vel faucibus in. Hendrerit viverra elementum venenatis pellentesque pellentesque ornare",
        author: "Helena Gibbson",
        date: "06 April 2023",
        imgSrc: "/assets/images/home/demo4/testimonial_avatar.png",
        imgWidth: 100,
        imgHeight: 100,
    },
    {
        id: 3,
        quote: "Ipsum dolor sit amet, consectetur adipiscing elit. Fringilla vel tincidunt ipsum ac. Nam at et id leo pulvinar egestas mi lorem. Adipiscing felis, vel faucibus in. Hendrerit viverra elementum venenatis pellentesque pellentesque ornare",
        author: "Helena Gibbson",
        date: "06 April 2023",
        imgSrc: "/assets/images/home/demo4/testimonial_avatar.png",
        imgWidth: 100,
        imgHeight: 100,
    },
    {
        id: 4,
        quote: "Ipsum dolor sit amet, consectetur adipiscing elit. Fringilla vel tincidunt ipsum ac. Nam at et id leo pulvinar egestas mi lorem. Adipiscing felis, vel faucibus in. Hendrerit viverra elementum venenatis pellentesque pellentesque ornare",
        author: "Helena Gibbson",
        date: "06 April 2023",
        imgSrc: "/assets/images/home/demo4/testimonial_avatar.png",
        imgWidth: 100,
        imgHeight: 100,
    },
];

export const testimonials2 = [
    {
        id: 1,
        title: "Good Theme",
        content:
            "Without JobHunt i’d be homeless, they found me a job and got me sorted out quickly with everything! Can’t quite… The Mitech team works really hard to ensure high level of quality",
        user: {
            name: "Nicole Wells",
            role: "Web Developer",
            avatar: "/assets/images/home/demo18/avatar-1.jpg",
        },
    },
    {
        id: 2,
        title: "Great Quality!",
        content:
            "Without JobHunt i’d be homeless, they found me a job and got me sorted out quickly with everything! Can’t quite… The Mitech team works really hard to ensure high level of quality",
        user: {
            name: "Gabriel Nolan",
            role: "Consultant",
            avatar: "/assets/images/home/demo18/avatar-2.jpg",
        },
    },
    {
        id: 3,
        title: "Awesome Design",
        content:
            "Without JobHunt i’d be homeless, they found me a job and got me sorted out quickly with everything! Can’t quite… The Mitech team works really hard to ensure high level of quality",
        user: {
            name: "Ashley Jenkins",
            role: "Designer",
            avatar: "/assets/images/home/demo18/avatar-3.jpg",
        },
    },
    {
        id: 1,
        title: "Good Theme",
        content:
            "Without JobHunt i’d be homeless, they found me a job and got me sorted out quickly with everything! Can’t quite… The Mitech team works really hard to ensure high level of quality",
        user: {
            name: "Nicole Wells",
            role: "Web Developer",
            avatar: "/assets/images/home/demo18/avatar-1.jpg",
        },
    },
    {
        id: 2,
        title: "Great Quality!",
        content:
            "Without JobHunt i’d be homeless, they found me a job and got me sorted out quickly with everything! Can’t quite… The Mitech team works really hard to ensure high level of quality",
        user: {
            name: "Gabriel Nolan",
            role: "Consultant",
            avatar: "/assets/images/home/demo18/avatar-2.jpg",
        },
    },
    {
        id: 3,
        title: "Awesome Design",
        content:
            "Without JobHunt i’d be homeless, they found me a job and got me sorted out quickly with everything! Can’t quite… The Mitech team works really hard to ensure high level of quality",
        user: {
            name: "Ashley Jenkins",
            role: "Designer",
            avatar: "/assets/images/home/demo18/avatar-3.jpg",
        },
    },
];

export default function Testimonials() {
    const swiperOptions = {
        autoplay: {
            delay: 5000,
        },
        slidesPerView: 1,
        slidesPerGroup: 1,
        modules: [Autoplay, Navigation, Pagination],
        effect: "none",
        loop: true,
        pagination: {
            el: ".testimonial-pagination",
            type: "bullets",
            clickable: true,
        },
        navigation: {
            nextEl: ".testimonial-carousel__next",
            prevEl: ".testimonial-carousel__prev",
        },
    };
    return (
        <section className="testimonials border-top">
            <div className="container">
                <div className="mb-1 pb-4 mb-xl-5 pb-xl-5"></div>
                <h2 className="section-title text-center fw-normal mb-1 mb-md-3 pb-xl-3 mb-xl-4 fs-3 fw-bold">Testimonials</h2>

                <div className="position-relative">
                    <Swiper
                        className="swiper-container js-swiper-slider"
                        pagination={{
                            el: ".testimonial-pagination",
                            type: "bullets",
                            clickable: true,
                        }}
                        modules={[Autoplay, Navigation, Pagination]}
                        navigation={{
                            nextEl: ".testimonial-carousel__next",
                            prevEl: ".testimonial-carousel__prev",
                        }}
                        loop
                        effect="none"
                        slidesPerGroup={1}
                        slidesPerView={1}
                        autoplay={{ delay: 5000 }}
                    >
                        {testimonials.map((elm, i) => (
                            <SwiperSlide key={i} className="swiper-slide testimonials__item mb-4 text-center">
                                <div className="w-740 mx-auto">
                                    <h5 className="fw-normal lh-2rem mb-4 fs-6">“ {elm.quote} “</h5>
                                    <p className="mb-3 tsn-black">
                                        {elm.author}, {elm.date}
                                    </p>
                                </div>
                            </SwiperSlide>
                        ))}
                        {/* <!-- /.swiper-wrapper --> */}
                    </Swiper>
                    {/* <!-- /.swiper-container js-swiper-slider --> */}

                    <div className="cursor-pointer products-carousel__prev testimonial-carousel__prev position-absolute top-50 d-flex align-items-center justify-content-center">
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    {/* <!-- /.products-carousel__prev --> */}
                    <div className="cursor-pointer products-carousel__next testimonial-carousel__next position-absolute top-50 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                    {/* <!-- /.products-carousel__next --> */}

                    <div className="testimonial-pagination type2 mt-4 d-flex align-items-center justify-content-center"></div>
                    {/* <!-- /.products-pagination --> */}
                </div>
                {/* <!-- /.position-relative --> */}

                <div className="mt-1 pt-4 mt-xl-5 pt-xl-5"></div>
            </div>
        </section>
    );
}
