import { Accordion } from "../../components/Accordion/Accordion";

const accordionData = [
    {
      id: 1,
      heading: "Bring of had which their whose you're it own?",
      body: `Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.`,
      expanded: true,
    },
    {
      id: 2,
      heading: "Over shall air can't subdue fly divide him?",
      body: `Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.`,
      expanded: false,
    },
    {
      id: 3,
      heading: "Waters one you'll creeping?",
      body: `Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.`,
      expanded: false,
    },
  ];
  
  const accordionData2 = [
    {
      id: 1,
      heading: "Bring of had which their whose you're it own?",
      body: `Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.`,
      expanded: true,
    },
    {
      id: 2,
      heading: "Over shall air can't subdue fly divide him?",
      body: `Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.`,
      expanded: false,
    },
    {
      id: 3,
      heading: "Waters one you'll creeping?",
      body: `Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.`,
      expanded: false,
    },
  ];
  const accordionData3 = [
    {
      id: 1,
      heading: "Bring of had which their whose you're it own?",
      body: `Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.`,
      expanded: true,
    },
    {
      id: 2,
      heading: "Over shall air can't subdue fly divide him?",
      body: `Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.`,
      expanded: false,
    },
    {
      id: 3,
      heading: "Waters one you'll creeping?",
      body: `Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.`,
      expanded: false,
    },
  ];

  export default function Faq() {
    return (
      <section style={{ minHeight: "86.7vh"}} className="container">
        <h2 className="section-title text-uppercase fw-bold my-5">
          FREQUENTLY ASKED QUESTIONS
        </h2>
        <h3 className="mb-4">Shipping</h3>
        <Accordion item={accordionData[0]} />
        <Accordion item={accordionData[1]} />
        <Accordion item={accordionData[2]} />
        <h3 className="mb-4">Orders</h3>
        <Accordion item={accordionData2[0]} />
        <Accordion item={accordionData2[1]} />
        <Accordion item={accordionData2[2]} />
        <h3 className="mb-4">Payment</h3>
        <Accordion item={accordionData3[0]} />
        <Accordion item={accordionData3[1]} />
        <Accordion item={accordionData3[2]} />
      </section>
    );
  }
  