import react, { useEffect, useState } from "react";
import "./shopping-cart-off-canvas.scss";
import { OffCanvasWrapper } from "../OffCanvasWrapper/OffCanvasWrapper";
import { CartItem, recalculateCartItems, useShoppingCart } from "../../context/ShoppingCartContext";
import { Stack } from "react-bootstrap";
import { CartItem as CartItemComp } from "../CartItem/CartItem";
import { Product, ProductApi } from "../../apis/ProductApi";
import { useAuthenticatedUser } from "../../context/AuthContext";
import { insertDecimalPlace } from "../../utils/function";
import { BootstrapButton } from "../BootstrapButton/BootstrapButton";
import { CustomerApi } from "../../apis/CustomerApi";
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ProductDiscountCreditApi } from "../../apis/ProductDiscountCreditApi";
import { BootstrapInput } from "../BootstrapInput/BootstrapInput";
import { CAProposition } from "../CAProposition/CAProposition";


const getSubTotalPrice = (cartItems: CartItem[], allProducts: Product[], isActiveMember: boolean) => {
    let totalAmount = 0;
    cartItems.forEach((cartItem, i) => {
        const getProductDetails = allProducts.find((item) => item.id === cartItem.id);
        if (getProductDetails) {
            if (isActiveMember) {
                let amount = cartItem.single
                    ? getProductDetails.member_single_price.unit_amount
                    : getProductDetails.member_multiple_price.unit_amount;
                totalAmount += cartItem.quantity * amount;
            } else {
                totalAmount += cartItem.quantity * (getProductDetails.retail_price.unit_amount ?? 0);
            }
        }
    });
    return totalAmount;
};

interface ShoppingCartOffCanvasProps {
    shoppingCartOffCanvasShow: boolean;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

export const ShoppingCartOffCanvas = ({ shoppingCartOffCanvasShow }: ShoppingCartOffCanvasProps) => {
    const { closeCart, cartItems, clearCart, setCartItems, allProducts } = useShoppingCart();
    const { authMember, updateAuthMember } = useAuthenticatedUser();
    const [clientSecret, setClientSecret] = useState("");
    const [sessionId, setSessionId] = useState("");
    const [pdcBalance, setPdcBalance] = useState(0);
    const [pdcAmountToUse, setPdcAmountToUse] = useState(0);
    const [recalcPdcBalance, setRecalcPdcBalanace] = useState(false);
    const [shippingToCalifornia, setShippingToCalifornia] = useState(false);
    const [hasCheckedWarning, setHasCheckedWarning] = useState(false);

    useEffect(() => {
        if (authMember) {
            ProductDiscountCreditApi.GetMemberBalance(authMember.member_id).then((response: any) => setPdcBalance(parseInt(response.results)));
            setRecalcPdcBalanace(false);
        }
    }, [authMember, recalcPdcBalance]);

    useEffect(() => {
        if (!shoppingCartOffCanvasShow && sessionId.length > 0) {
            CustomerApi.ExpireCheckoutSession(sessionId);
            setClientSecret("");
            setSessionId("");
        }
        if (!shoppingCartOffCanvasShow) {
            setShippingToCalifornia(false);
            setHasCheckedWarning(false);
            setPdcAmountToUse(0);
        }
    }, [shoppingCartOffCanvasShow, authMember]);

    const createCheckoutSession = async () => {
        //TEMPORARY
        if(cartItems.find((item) => item.id === "prod_QMplKEph6gdyDi") && authMember) {
            await CustomerApi.CreateSubAndCheckoutSession(
                    cartItems,
                    authMember.customer_id
                )
                    .then((response) => {
                        if (response.success) {
                            setSessionId(response.id);
                            setClientSecret(response.client_secret);
                            setHasCheckedWarning(false);
                            setShippingToCalifornia(false);
                        } else {
                            console.log("FAILED");
                        }
                    })
                    .catch(console.error);
        } {
            let checkoutSessionObj: any = {
                cartItems,
                customer_id: authMember ? authMember.customer_id : undefined,
                member_id: authMember ? authMember.member_id : undefined,
                amountToUse: pdcAmountToUse > 0 ? pdcAmountToUse : 0,
                member_active: false,
            };
    
            if (authMember) {
                let memberActive: any = await CustomerApi.CheckActiveMembership(authMember.customer_id);
                if (memberActive.valid_member) {
                    checkoutSessionObj.member_active = true;
                } else {
                    checkoutSessionObj.cartItems = recalculateCartItems(false, cartItems, setCartItems, allProducts, "Return");
                    checkoutSessionObj.amountToUse = 0;
                }
            }
            await CustomerApi.CreateCheckoutSession(
                checkoutSessionObj.cartItems,
                checkoutSessionObj.customer_id,
                checkoutSessionObj.member_id,
                checkoutSessionObj.amountToUse
            )
                .then((response) => {
                    if (response.success) {
                        setSessionId(response.id);
                        setClientSecret(response.client_secret);
                        setHasCheckedWarning(false);
                        setShippingToCalifornia(false);
                    } else {
                        console.log("FAILED");
                    }
                })
                .catch(console.error);
        }
    };

    const handlePdcInputOnChange = (e: react.ChangeEvent<HTMLInputElement>) => {
        let subTotal = getSubTotalPrice(cartItems, allProducts, authMember?.active ? authMember.active : false);
        if (parseInt(e.target.value) < 0) {
            setPdcAmountToUse(0);
        } else if (parseInt(e.target.value) > pdcBalance) {
            setPdcAmountToUse(pdcBalance);
        } else if (parseInt(e.target.value) > subTotal / 100) {
            setPdcAmountToUse(parseInt((subTotal / 100 - 1).toFixed()));
        } else {
            setPdcAmountToUse(parseInt(e.target.value));
        }
    };

    const refreshMembership = async () => {
        if(authMember && cartItems.find((item) => item.id === "prod_QMplKEph6gdyDi")) {
            await CustomerApi.GetCustomerSubDetails(authMember.customer_id).then((customerResponse: any) => {
                updateAuthMember({
                    ...authMember,
                    active: customerResponse.valid_member,
                });
            })
        }
    }


    return (
        <OffCanvasWrapper
            offCanvasPlacement="end"
            showOffCanvas={shoppingCartOffCanvasShow}
            setShowOffCanvas={closeCart}
            offCanvasTitle="Shopping Cart"
            offCanvasBody={
                cartItems.length === 0 ? (
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <div className="fs-5 text-center">Your Cart Is Currently Empty.</div>
                    </div>
                ) : (
                    <>
                        {clientSecret === "" && (
                            <Stack className="shopping-cart__stack" gap={3}>
                                {cartItems.map((item) => (
                                    <CartItemComp key={`${item.id}-${item.single && item.single}`} {...item} allProducts={allProducts} />
                                ))}
                            </Stack>
                        )}
                        {clientSecret === "" && (
                            <div className="my-4 d-flex justify-content-end">
                                <b>
                                    SubTotal: $
                                    {insertDecimalPlace(getSubTotalPrice(cartItems, allProducts, authMember?.active ? authMember.active : false))}
                                </b>
                            </div>
                        )}
                        {clientSecret === "" && pdcBalance > 0 && !cartItems.find((item) => item.id === "prod_RKnFDT2FYYW9wE" || item.id === "prod_RKnDK7U7sbY6ye") && (
                            <div className="border-top border-bottom py-3">
                                <div className="fs-5">
                                    Product Discount Credits Available (<span className="tsn-green tsn-medium-bold">{pdcBalance}</span>)
                                </div>
                                <BootstrapInput
                                    className="mt-3"
                                    max={pdcBalance}
                                    min={0}
                                    onChange={(e) => handlePdcInputOnChange(e)}
                                    value={pdcAmountToUse === 0 ? "" : pdcAmountToUse.toString()}
                                    type="number"
                                    label="Amount to Use"
                                />
                            </div>
                        )}
                        {(!authMember || (authMember && authMember.address === null)) && clientSecret === "" && (
                            <div className="my-4 mx-3">
                                <BootstrapInput
                                    className="mt-4"
                                    type="checkbox"
                                    value={shippingToCalifornia}
                                    onChange={() => {
                                        setShippingToCalifornia(!shippingToCalifornia);
                                        setHasCheckedWarning(false);
                                    }}
                                    label="Are you shipping to California?"
                                />
                            </div>
                        )}
                        {shippingToCalifornia && clientSecret.length === 0 && (
                            <CAProposition isChecked={hasCheckedWarning} setIsChecked={setHasCheckedWarning} />
                        )}
                        {clientSecret === "" && (
                            <BootstrapButton
                                disabled={shippingToCalifornia && !hasCheckedWarning}
                                onClick={createCheckoutSession}
                                className="mt-4 w-100"
                                title={"Checkout"}
                            />
                        )}
                        {clientSecret.length > 0 && (
                            <EmbeddedCheckoutProvider
                                stripe={stripePromise}
                                options={{
                                    clientSecret,
                                    onComplete: () => {
                                        setRecalcPdcBalanace(true);
                                        refreshMembership();
                                        closeCart();
                                        clearCart();
                                    },
                                }}
                            >
                                <EmbeddedCheckout />
                            </EmbeddedCheckoutProvider>
                        )}
                    </>
                )
            }
            fullWidth
        />
    );
};
