import { Stack } from "react-bootstrap";
import { Product } from "../../apis/ProductApi";
import { useShoppingCart } from "../../context/ShoppingCartContext";
import { useAuthenticatedUser } from "../../context/AuthContext";
import { BootstrapButton } from "../BootstrapButton/BootstrapButton";
import { insertDecimalPlace } from "../../utils/function";

interface CartItemProps {
    id: string;
    quantity: number;
    single: boolean;
    allProducts: Product[];
}

export const CartItem = ({ id, quantity, single, allProducts }: CartItemProps) => {
    const { removeFromCart, removeFromCartAuth } = useShoppingCart();
    const { authMember } = useAuthenticatedUser();
    const item = allProducts.find((product) => product.id == id);
    if (item == null) return null;

    return (
        <>
            <Stack direction="horizontal" gap={2} className="m-1 p-2 border-radius border-bottom mt-2 d-flex align-items-center border">
                <div className="me-auto">
                    <div>
                        {item.name}{" "}
                        {quantity > 1 && (
                            <span className="text-muted" style={{ fontSize: ".85rem" }}>
                                x{quantity}
                            </span>
                        )}
                    </div>
                    <div className="text-muted fs-6">
                        <b>
                            {authMember && authMember.active
                                ? single
                                    ? item.member_single_price.unit_amount_formatted
                                    : item.member_multiple_price.unit_amount_formatted
                                : item.retail_price.unit_amount_formatted ?? "$14.95 / month after"}
                        </b>
                    </div>
                </div>
                <div className="d-flex align-items-center gap-2">
                    <b>
                        {authMember && authMember.active
                            ? single
                                ? `$${insertDecimalPlace(item.member_single_price.unit_amount * quantity)}`
                                : `$${insertDecimalPlace(item.member_multiple_price.unit_amount * quantity).toString()}`
                            : item.retail_price.unit_amount && quantity ? `$${insertDecimalPlace(item.retail_price.unit_amount * quantity)}` : "FREE"}
                    </b>
                    {item.id !== "prod_QMplKEph6gdyDi" && <BootstrapButton onClick={() => authMember && authMember.active ? removeFromCartAuth(item.id, single) : removeFromCart(item.id)} variant="outline-danger" title="X" />}
                </div>
            </Stack>
        </>
    );
};
