import React, { useContext, useEffect, useState } from "react";
import "./default-bootstrap-overrides.scss";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "react-toastify/dist/ReactToastify.css";
import { LandingPage } from "./pages/LandingPage/LandingPage";
import { BrowserRouter, Route, Routes, Navigate, Outlet } from "react-router-dom";
import { AboutPage } from "./pages/AboutPage/AboutPage";
import { SignUpPage } from "./pages/SignUpPage/SignUpPage";
import { Footer } from "./components/Footer/Footer";
import { SignInOffCanvas } from "./components/SignInOffCanvas/SignInOffCanvas";
import { Header } from "./components/Header/Header";
import { PasswordResetOffCanvas } from "./components/PasswordResetOffCanvas/PasswordResetOffCanvas";
import { ProductPage } from "./pages/ProductPage/ProductPage";
import { AuthContext, AuthProvider } from "./context/AuthContext";
import { ShoppingCartProvider } from "./context/ShoppingCartContext";
import { VerifyPage } from "./pages/VerifyPage/VerifyPage";
import { AccountPage } from "./pages/AccountPage/AccountPage";
import { SuccessfulPaymentPagee } from "./pages/SuccessfulPaymentPage/SuccessfulPaymentPage";
import { ProductDetailsPage } from "./pages/ProductDetailsPage/ProductDetailsPage";
import { MobileSideNavigationCanvas } from "./components/MobileSideNavigationOffCanvas/MobileSideNavigationOffCanvas";
import Faq from "./pages/FAQPage/FAQPage";

enum Membership_Type {
    monthly = 1,
    yearly,
    group_owner,
    group_member
}

export const test_monthly_id = "prod_Pi3pE7Ntd7JidH";
export const test_yearly_id = "prod_QKZr3CM7hkOTG6";
export const test_group_owner_ten = "prod_QcZfVzdp8AReeM";
export const test_group_owner_twenty = "prod_QcZfqeEECWAods";
export const test_group_owner_forty = "prod_QcZgcJdEMGeB9d";
export const production_monthly_id = "prod_QE92X8x8gdrS78";
export const production_yearly_id = "prod_QOd1SiJr5BPTuP";
export const production_group_owner_ten = "";
export const production_group_owner_twenty = "";
export const production_group_owner_forty = "";

export const curr_subscriptions = [
    { id: test_monthly_id, type: Membership_Type.monthly },
    { id: test_yearly_id, type: Membership_Type.yearly },
    { id: test_group_owner_ten, type: Membership_Type.group_owner },
    { id: test_group_owner_twenty, type: Membership_Type.group_owner },
    { id: test_group_owner_forty, type: Membership_Type.group_owner },
    { id: production_monthly_id, type: Membership_Type.monthly },
    { id: production_yearly_id, type: Membership_Type.yearly },
    { id: production_group_owner_ten, type: Membership_Type.group_owner },
    { id: production_group_owner_twenty, type: Membership_Type.group_owner },
    { id: production_group_owner_forty, type: Membership_Type.group_owner }
]

export const getAffiliateLink = () => {
    const currentUrl = window.location;
    let path = currentUrl.pathname;
    path = path.replace("/account/signup", "");
    path = path.replace("/account/signup/", "");
    path = path.replace("/", "");
    if (path.length === 6 || (path.length === 9 && path.includes('gm_'))) {
        localStorage.setItem("affiliate", path);
    } else {
        localStorage.removeItem("affiliate");
    }
};

function App() {
    const [signInOffCanvasShow, setSignInOffCanvasShow] = useState(false);
    const [passwordResetOffCanvasShow, setPasswordResetOffCanvasShow] = useState(false);
    const [mobileSideNavigationCanvasShow, setMobileSideNavigationCanvasShow] = useState(false);

    useEffect(() => {
        updateCCInUrl();
        getAffiliateLink();
        getResetPasswordRequest();
    }, []);

    const updateCCInUrl = () => {
        const currentUrl = window.location;
        if (!currentUrl.pathname.includes("account") && currentUrl.search.includes("updateCC=true")) {
            setSignInOffCanvasShow(true);
        }
    };

    const getResetPasswordRequest = () => {
        if (window.location.search.includes("pr=true")) {
            setPasswordResetOffCanvasShow(true);
        }
    };

    return (
        <div className="app">
            <BrowserRouter>
                <AuthProvider>
                    <ShoppingCartProvider>
                        <SignInOffCanvas
                            setPasswordResetOffCanvasShow={setPasswordResetOffCanvasShow}
                            signInOffCanvasShow={signInOffCanvasShow}
                            setSignInOffCanvasShow={setSignInOffCanvasShow}
                        />
                        <PasswordResetOffCanvas
                            passwordResetOffCanvasShow={passwordResetOffCanvasShow}
                            setPasswordResetOffCanvasShow={setPasswordResetOffCanvasShow}
                            setSignInOffCanvasShow={setSignInOffCanvasShow}
                        />
                        <MobileSideNavigationCanvas
                            mobileSideNavigationShow={mobileSideNavigationCanvasShow}
                            setMobileSideNavigationShow={setMobileSideNavigationCanvasShow}
                            setSignInOffCanvasShow={setSignInOffCanvasShow}
                        />
                        <Header
                            setSignInOffCanvasShow={setSignInOffCanvasShow}
                            setMobileSideNavigationCanvasShow={setMobileSideNavigationCanvasShow}
                        />
                        <Routes>
                            <Route path="/" element={<LandingPage setSignInOffCanvasShow={setSignInOffCanvasShow} />} />
                            <Route path="/:affiliateLink" element={<LandingPage setSignInOffCanvasShow={setSignInOffCanvasShow} />} />
                            <Route path="/successfulPurchase" element={<SuccessfulPaymentPagee />} />
                            <Route path="/ourStory/" element={<AboutPage />} />
                            <Route path="/FAQs/" element={<Faq />} />
                            <Route path="/account/signup/" element={<SignUpPage />} />
                            <Route path="/account/verify" element={<VerifyPage />} />
                            <Route path="/account/signup/:affiliateLink" element={<SignUpPage />} />
                            <Route path="/products/" element={<ProductPage />} />
                            <Route path="/products/:id" element={<ProductDetailsPage />} />
                            {/* Private Routes */}
                            <Route element={<PrivateRoutes />}>
                                <Route path="/account/" element={<AccountPage />} />
                            </Route>
                        </Routes>
                        <Footer setMobileSideNavigationCanvasShow={setMobileSideNavigationCanvasShow} />
                    </ShoppingCartProvider>
                </AuthProvider>
            </BrowserRouter>
        </div>
    );
}

const PrivateRoutes = () => {
    let auth = localStorage.getItem("member");
    return auth ? <Outlet /> : <Navigate to="/" />;
};

export default App;
